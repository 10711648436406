import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  status: null,
  errormsg: null,
  loading: false,
  reportBankDis : null 
}

const reportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    onChangeStatus(state, { payload }) {
      state.status = payload
    },
    onError(state, { payload }) {
      state.status = 'error'
      state.errormsg = payload
    },
    generateFileDis() { },
    onReportResponse(state, { payload }) {
      console.log(payload);
      state.reportBankDis = payload
    },
  }
})

const reportsActions = reportsSlice.actions
const reportsReducer = reportsSlice.reducer

export { reportsActions, reportsReducer }