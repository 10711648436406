import { fork, all } from 'redux-saga/effects'
import AuthSaga from '../services/Auth/AuthSaga'
import SpecialistSaga from '../services/Specialist/SpecialistSaga'
import UserSaga from '../services/User/UserSaga'
import PropertySaga from '../services/Property/PropertySaga'
import LocationSaga from '../services/Location/LocationSaga'
import GenreSaga from '../services/Genre/GenreSaga'
import SpecialitySaga from '../services/Speciality/SpecialitySaga'
import DocIdTypeSaga from '../services/DocIdType/DocIdTypeSaga'
import ShoppingSaga from '../services/Shopping/ShoppingSaga'
import BankSaga from '../services/Bank/BankSaga'
import ContractSaga from '../services/Contract/ContractSaga'
import PermissionSaga from '../services/Permission/PermissionSaga'
import TicketSaga from '../services/Ticket/TicketSaga'
import companySaga from '../services/Company/CompanySaga'
import NotificationSaga from '../services/Notification/NotificationSaga'
import DiscountsSaga from 'services/Discounts/DiscountsSaga'
import ContractDispersionSaga from 'services/ContractDispersion/ContractDispersionSaga'
import InventorySaga from 'services/Inventory/InventorySaga'
import FeesSaga from 'services/Fees/FeesSaga'
import TwilioSaga from 'services/Twilio/TwilioSaga'
import StateAccountSaga from 'services/StatusAccount/StateAccountSaga'
import DispersionSaga from 'services/Dispersion/DispersionSaga'
import PaymentSendSaga from 'services/PaymentSend/PaymentSendSaga'
import EmailSaga from 'services/Email/EmailSaga'
import AppSaga from 'services/App/AppSaga'
import MediaSaga from 'services/Media/MediaSaga'
import SysBlueprintSaga from 'services/SysBlueprint/SysBlueprintSaga'
import BillingSaga  from 'services/Billing/BillingSaga'
import FilesReaderSaga from 'services/FilesReader/FilesReaderSaga'
import ReportsSaga from 'services/Reports/ReportsSaga'

export default function* rootSaga() {
    yield all([
        fork(AuthSaga),
        fork(SpecialistSaga),
        fork(UserSaga),
        fork(PropertySaga),
        fork(LocationSaga),
        fork(GenreSaga),
        fork(SpecialitySaga),
        fork(DocIdTypeSaga),
        fork(ShoppingSaga),
        fork(BankSaga),
        fork(ContractSaga),
        fork(PermissionSaga),
        fork(TicketSaga),
        fork(companySaga),
        fork(NotificationSaga),
        fork(DiscountsSaga),
        fork(ContractDispersionSaga),
        fork(InventorySaga),
        fork(FeesSaga),
        fork(TwilioSaga),
        fork(DispersionSaga),
        fork(StateAccountSaga),
        fork(PaymentSendSaga),
        fork(EmailSaga),
        fork(AppSaga),
        fork(MediaSaga),
        fork(SysBlueprintSaga),
        fork(BillingSaga),
        fork(FilesReaderSaga),
        fork(ReportsSaga)
    ])
}