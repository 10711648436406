import { combineReducers } from '@reduxjs/toolkit'
import { authReducer } from '../services/Auth/AuthSlice'
import { specialistReducer } from '../services/Specialist/SpecialistSlice'
import { userReducer } from '../services/User/UserSlice'
import { propertyReducer } from '../services/Property/PropertySlice'
import { locationReducer } from '../services/Location/LocationSlice'
import { genreReducer } from '../services/Genre/GenreSlice'
import { specialityReducer } from '../services/Speciality/SpecialitySlice'
import { docIdTypeReducer } from '../services/DocIdType/DocIdTypeSlice'
import { shoppingReducer } from '../services/Shopping/ShoppingSlice'
import { bankReducer } from '../services/Bank/BankSlice'
import { contractReducer } from '../services/Contract/ContractSlice'
import { permissionReducer } from '../services/Permission/PermissionSlice'
import { ticketReducer } from '../services/Ticket/TicketSlice'
import { companyReducer } from '../services/Company/CompanySlice'
import { notificationReducer } from '../services/Notification/NotificationSlice'
import { discountsReducer } from 'services/Discounts/DiscountsSlice'
import { contractDispersionReducer } from 'services/ContractDispersion/ContractDispersionSlice'
import { inventoryReducer } from 'services/Inventory/InventorySlice'
import { feesReducer } from 'services/Fees/FeesSlice'
import { twilioReducer } from 'services/Twilio/TwilioSlice'
import { dispersionReducer } from 'services/Dispersion/DispersionSlice'
import { stateAccountReducer } from 'services/StatusAccount/StateAccountSlice'
import { PaymentSendReducer } from 'services/PaymentSend/PaymentSendSlice'
import { emailReducer } from 'services/Email/EmailSlice'
import { appReducer } from 'services/App/AppSlice'
import { mediaReducer } from 'services/Media/MediaSlice'
import { sysBlueprintReducer } from  'services/SysBlueprint/SysBlueprintSlice'
import { billingReducer } from 'services/Billing/BillingSlice'
import { filesReaderReducer } from 'services/FilesReader/FilesReaderSlice'
import { reportsReducer } from 'services/Reports/ReportsSlice'

const combineReducer = combineReducers({
  auth: authReducer,
  specialist: specialistReducer,
  user: userReducer,
  property: propertyReducer,
  location: locationReducer,
  genre: genreReducer,
  speciality: specialityReducer,
  docIdType: docIdTypeReducer,
  shopping: shoppingReducer,
  bank: bankReducer,
  contract: contractReducer,
  permission: permissionReducer,
  ticket: ticketReducer,
  company: companyReducer,
  notification: notificationReducer,
  discounts: discountsReducer,
  contracDispersion: contractDispersionReducer,
  inventory: inventoryReducer,
  fees: feesReducer,
  twilio: twilioReducer,
  dispersion: dispersionReducer,
  stateAccount: stateAccountReducer,
  paymentSend: PaymentSendReducer,
  email: emailReducer,
  media: mediaReducer,
  app: appReducer,
  sysblueprint:sysBlueprintReducer,
  billing:billingReducer,
  filesReader:filesReaderReducer,
  reports:reportsReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout')
    state = undefined
  return combineReducer(state, action)
}

export default rootReducer