import { put, all, takeLatest } from 'redux-saga/effects'
import ApiService from '../../common/api/Api'
import { reportsActions } from './ReportsSlice'

function* generateFileDis({ payload }) {
  yield put(reportsActions.onChangeStatus("loading"));
  try {
    const { type, data } = payload;
    // Realizar la solicitud para generar el archivo en el backend
    const response = yield ApiService.requestPostFileXlxs(`/reports/dispersion_bank/${type}`, { data }, {
      responseType: 'blob', // Asegúrate de que esperamos un archivo binario
    }); 
    // Comprobar si la respuesta es un Blob
    const fileBlob = response; 
    if (!(fileBlob instanceof Blob)) {
      throw new Error("No se ha recibido un archivo válido.");
    } 
    // Crear una URL para el Blob (esto es necesario para permitir la descarga en el navegador)
    const fileURL = window.URL.createObjectURL(fileBlob); 
    // Crear un enlace de descarga temporal
    const a = document.createElement('a');
    a.href = fileURL;
    a.download = `dispersion_${type}.xlsx`; // Nombre del archivo que deseas descargar
    a.style.display = 'none'; // No mostrar el enlace en la página
    document.body.appendChild(a);
    a.click(); // Iniciar la descarga

    // Revocar la URL temporal después de que se haya descargado el archivo
    window.URL.revokeObjectURL(fileURL);
   // yield put(reportsActions.onReportResponse(response));
    yield put(reportsActions.onChangeStatus("success"));
  } catch (err) { 
    yield put(reportsActions.onError(err.toString()));
  }

}

function* ActionWatcher() {
  yield takeLatest(reportsActions.generateFileDis, generateFileDis)
}

export default function* () {
  yield all([
    ActionWatcher(),
  ])
}
